const imagenesBD = [
    {categoria:'abertura' ,
    url:"assets/img/portfolio/1.jpg"
    },
    {categoria:'techo' ,
    url:"assets/img/portfolio/2.jpg"
    },
    {categoria:'techo' ,
    url:"assets/img/portfolio/3.jpg"
    },
    {categoria:'terraza' ,
    url:"assets/img/portfolio/4.jpg"
    },
    {categoria:'mampara' ,
    url:"assets/img/portfolio/6.jpg"
    },
    {categoria:'techo' ,
    url:"assets/img/portfolio/5.jpg"
    },
    {categoria:'abertura' ,
    url:"assets/img/portfolio/7.jpg"
    },
    {cat:'techo' ,
    url:"assets/img/portfolio/8.jpg"
    },
    {cat:'abertura' ,
    url:"assets/img/portfolio/9.jpg"
    },
    {categoria:'abertura' ,
    url:"assets/img/portfolio/10.jpg"
    },
    {categoria:'abertura' ,
    url:"assets/img/portfolio/11.jpg"
    },
    {categoria:'abertura' ,
    url:"assets/img/portfolio/12.jpg"
    },
    {categoria:'mampara' ,
    url:"assets/img/portfolio/13.jpg"
    },
    {categoria:'techo' ,
    url:"assets/img/portfolio/14.jpg"
    },
    {categoria:'terraza' ,
    url:"assets/img/portfolio/16.jpg"
    },
    {categoria:'abertura' ,
    url:"assets/img/portfolio/15.jpg"
    },
    {categoria:'abertura' ,
    url:"assets/img/portfolio/17.jpg"
    },
    {cat:'abertura' ,
    url:"assets/img/portfolio/18.jpg"
    },
    {cat:'abertura' ,
    url:"assets/img/portfolio/19.jpg"
    },]

    export default imagenesBD